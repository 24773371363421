<script setup>
import MainSection from '@/components/MainSection.vue'
import {onMounted} from "vue";
import { createChart } from 'lightweight-charts';
import axios from "axios";
const TRADING_API_URL = process.env.VUE_APP_TRADING_API_HOST

onMounted(async () => {
  console.log(TRADING_API_URL)
  const chartOptions = {
    height: 400,
    width: 900,
    layout: { textColor: 'black', background: { type: 'solid', color: 'white' } }
  };
  const chart = createChart('chart-container', chartOptions);

  const candlestickSeries = chart.addCandlestickSeries({
    upColor: '#26a69a', downColor: '#ef5350', borderVisible: false,
    wickUpColor: '#26a69a', wickDownColor: '#ef5350',
  });

  await axios.get(TRADING_API_URL + 'candles')
    .then((response) => {
      candlestickSeries.setData(response.data.map((el) => {
        return { time: el[0] / 1000, open: parseFloat(el[1]), high: parseFloat(el[2]), low: parseFloat(el[3]), close: parseFloat(el[4]) }
      }));
    });
  await axios.get(TRADING_API_URL + 'extremum')
    .then((response) => {
      candlestickSeries.setMarkers(response.data.map((el) => {
        return {
          time: el.open_time / 1000,
          position: el.min_extremum ? 'belowBar' : 'aboveBar',
          color: el.min_extremum ? 'green' : 'red',
          shape: el.min_extremum ? 'arrowUp' : 'arrowDown',
          text: el.min_extremum ? 'min ex' : 'max ex'
        }
      }));
    });

  chart.timeScale().fitContent();
})
</script>

<template>
  <main-section>
    <div id="chart-container"></div>
  </main-section>
</template>
