import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from '@/pages/Dashboard.vue'
import Bots from '@/pages/Bots.vue'
import BotCreate from '@/pages/BotCreate.vue'
import Exchanges from '@/pages/Exchanges.vue'
import Deals from '@/pages/Deals.vue'
import ExchangeConnectionCreate from '@/pages/ExchangeConnectionCreate.vue'
import UserCreate from '@/pages/UserCreate.vue'
import Orders from '@/pages/Orders.vue'
import Users from '@/pages/Users.vue'
import BotDeals from '@/pages/BotDeals.vue'
import UserBots from '@/pages/UserBots.vue'
import Payments from '@/pages/Payments.vue'
import UserView from '@/pages/UserView.vue'
import UserAccountBalance from "@/pages/UserAccountBalance.vue";
import Signals from "@/pages/Signals.vue";
import Tickers from "@/pages/Tickers.vue";
import TickerSettings from "@/pages/TickerSettings.vue";
import TickerSettingPairCreate from "@/pages/TickerSettingPairCreate.vue";
import Backtesting from "@/pages/Backtesting.vue";

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard'
    },
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    meta: {
      title: 'Bots'
    },
    path: '/bots',
    name: 'bots',
    component: Bots
  },
  {
    meta: {
      title: 'Create bot'
    },
    path: '/bot/create',
    name: 'create-bot',
    component: BotCreate
  },
  {
    meta: {
      title: 'New exchange connection'
    },
    path: '/exchange-connection/create',
    name: 'create-exchange',
    component: ExchangeConnectionCreate
  },
  {
    meta: {
      title: 'Exchanges'
    },
    path: '/exchanges',
    name: 'exchanges',
    component: Exchanges
  },
  {
    meta: {
      title: 'Users'
    },
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    meta: {
      title: 'Bot deals'
    },
    path: '/bot-deals/:bot_id',
    name: 'bot-deals',
    component: BotDeals
  },
  {
    meta: {
      title: 'User bots'
    },
    path: '/user/:user_id/bots',
    name: 'user-bots',
    component: UserBots
  },
  {
    meta: {
      title: 'User information'
    },
    path: '/user/:user_id',
    name: 'user-view',
    component: UserView
  },
  {
    meta: {
      title: 'Account balance'
    },
    path: '/user/account/:exchange_id',
    name: 'account-balance',
    component: UserAccountBalance
  },
  {
    meta: {
      title: 'Users'
    },
    path: '/create-user',
    name: 'create-user',
    component: UserCreate
  },
  {
    meta: {
      title: 'Signals'
    },
    path: '/signals',
    name: 'signals',
    component: Signals
  },
  {
    meta: {
      title: 'Back-testing'
    },
    path: '/back-testing',
    name: 'back-testing',
    component: Backtesting
  },
  {
    meta: {
      title: 'Tickers'
    },
    path: '/tickers',
    name: 'tickers',
    component: Tickers
  },
  {
    meta: {
      title: 'Ticker Settings'
    },
    path: '/ticker-settings',
    name: 'ticker-settings',
    component: TickerSettings
  },
  {
    meta: {
      title: 'Ticker Setting Pair Create'
    },
    path: '/ticker-setting-pair-create',
    name: 'ticker-setting-pair-create',
    component: TickerSettingPairCreate
  },
  {
    meta: {
      title: 'Payments'
    },
    path: '/payments',
    name: 'payments',
    component: Payments
  },
  {
    meta: {
      title: 'Bot deals'
    },
    path: '/deals/:bot_id',
    name: 'deals',
    component: Deals
  },
  {
    meta: {
      title: 'Deal orders'
    },
    path: '/orders/:deal_id',
    name: 'orders',
    component: Orders
  },
  {
    meta: {
      title: 'Tables'
    },
    path: '/tables',
    name: 'tables',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Tables.vue')
  },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/Forms.vue')
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'Ui'
    },
    path: '/ui',
    name: 'ui',
    component: () => import(/* webpackChunkName: "ui" */ '@/views/Ui.vue')
  },
  {
    meta: {
      title: 'Responsive layout'
    },
    path: '/responsive',
    name: 'responsive',
    component: () => import(/* webpackChunkName: "responsive" */ '@/views/Responsive.vue')
  },
  {
    meta: {
      title: 'Login',
      fullScreen: true
    },
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    meta: {
      title: 'Error',
      fullScreen: true
    },
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
