<script setup>
import { reactive } from 'vue'
import { mdiBallot } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import Field from '@/components/Field.vue'
import Control from '@/components/Control.vue'
import Divider from '@/components/Divider.vue'
import JbButton from '@/components/JbButton.vue'
import JbButtons from '@/components/JbButtons.vue'
import UserService from '@/services/user.service'
import router from '@/router'

const form = reactive({
  email: '',
  username: '',
  password: '',
  referral_code: ''
})

const submit = () => {
  UserService.createUser(form).then(() => {
    router.push({ name: 'users' })
  }).catch((reason) => {
    console.log(reason)
    if (reason.response.status === 400) {
      if (Array.isArray(reason.response.data.message)) {
        alert(reason.response.data.message[0])
      } else {
        alert(reason.response.data.message)
      }
    } else {
      alert('Undefined error')
    }
  })
}
</script>

<template>
  <main-section>
    <card-component
      title="New user"
      :icon="mdiBallot"
      form
      @submit.prevent="submit"
    >
      <field label="Email">
        <control v-model="form.email" />
      </field>
      <field label="Username">
        <control v-model="form.username" />
      </field>
      <field label="Password">
        <control v-model="form.password" />
      </field>
      <field label="Referral code">
        <control v-model="form.referral_code" />
      </field>

      <divider />

      <jb-buttons>
        <jb-button
          type="submit"
          color="info"
          label="Submit"
        />
      </jb-buttons>
    </card-component>
  </main-section>
</template>
