<script setup>
import { computed, ref } from 'vue'
import { useMainStore } from '@/stores/main'
import CheckboxCell from '@/components/CheckboxCell.vue'
import Level from '@/components/Level.vue'
import JbButtons from '@/components/JbButtons.vue'
import JbButton from '@/components/JbButton.vue'
import UserService from '@/services/user.service'
import { useRoute } from 'vue-router'

defineProps({
  checkable: Boolean
})

const mainStore = useMainStore()

const lightBorderStyle = computed(() => mainStore.lightBorderStyle)

const lightBgStyle = computed(() => mainStore.lightBgStyle)

const tableTrStyle = computed(() => mainStore.tableTrStyle)

const tableTrOddStyle = computed(() => mainStore.tableTrOddStyle)

const darkMode = computed(() => mainStore.darkMode)

const route = useRoute()

const items = computed(() => mainStore.bots)
UserService.getBotsByUserId(route.params.user_id).then((res) => {
  mainStore.bots = (res.data)
})

const perPage = ref(10)

const currentPage = ref(0)

const checkedRows = ref([])

const itemsPaginated = computed(
  () => items.value.slice(perPage.value * currentPage.value, perPage.value * (currentPage.value + 1))
)

const numPages = computed(() => Math.ceil(items.value.length / perPage.value))

const currentPageHuman = computed(() => currentPage.value + 1)

const pagesList = computed(() => {
  const pagesList = []

  for (let i = 0; i < numPages.value; i++) {
    pagesList.push(i)
  }

  return pagesList
})

const remove = (arr, cb) => {
  const newArr = []

  arr.forEach(item => {
    if (!cb(item)) {
      newArr.push(item)
    }
  })

  return newArr
}

const checked = (isChecked, item) => {
  if (isChecked) {
    checkedRows.value.push(item)
  } else {
    checkedRows.value = remove(checkedRows.value, row => row._id === item._id)
  }
}
</script>

<template>
  <div
    v-if="checkedRows.length"
    class="bg-opacity-50 p-3 dark:bg-gray-800"
    :class="lightBgStyle"
  >
    <span
      v-for="checkedRow in checkedRows"
      :key="checkedRow._id"
      class="inline-block px-2 py-1 rounded-sm mr-2 text-sm dark:bg-gray-700"
      :class="lightBgStyle"
    >
      {{ checkedRow._id }}
    </span>
  </div>

  <table>
    <thead>
      <tr v-if="itemsPaginated.length > 0">
        <th v-if="checkable" />
        <th v-for="(value, key) in itemsPaginated[0]" v-bind:key="key">
          {{key}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in itemsPaginated"
        :key="row.id"
        :class="[tableTrStyle, index % 2 === 0 ? tableTrOddStyle : '']"
      >
        <checkbox-cell
          v-if="checkable"
          @checked="checked($event, row)"
        />

        <td
          v-for="(value, key) in row"
          :key="row.id + key"
          :data-label="key"
        >
          {{ value }}
        </td>

        <td data-label="actions">

          <jb-buttons>
            <jb-button
              :to="{ name: 'bot-deals', params: { bot_id: row._id }}"
              small
              label="Deals"
            />
          </jb-buttons>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <div
    :class="lightBorderStyle"
    class="p-3 lg:px-6 border-t dark:border-gray-800"
  >
    <level>
      <jb-buttons>
        <jb-button
          v-for="page in pagesList"
          :key="page"
          :active="page === currentPage"
          :label="page + 1"
          :outline="darkMode"
          small
          @click="currentPage = page"
        />
      </jb-buttons>
      <small>Page {{ currentPageHuman }} of {{ numPages }}</small>
    </level>
  </div>
</template>
