<script setup>
import { reactive } from 'vue'
import { mdiBallot } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import Field from '@/components/Field.vue'
import Control from '@/components/Control.vue'
import Divider from '@/components/Divider.vue'
import JbButton from '@/components/JbButton.vue'
import JbButtons from '@/components/JbButtons.vue'
import UserService from '@/services/user.service'
import { useMainStore } from '@/stores/main'
import router from '@/router'

const mainStore = useMainStore()

const types = ['binance-spot']

UserService.getExchanges().then((res) => {
  mainStore.exchanges = (res.data)
})
const form = reactive({
  type: types[0],
  api_key: '',
  secret_key: ''
})

const submit = () => {
  UserService.createExchangeConnection({
    type: types[0],
    api_key: form.api_key,
    secret_key: form.secret_key
  }).then(() => {
    router.push({ name: 'exchanges' })
  })
}
const check = () => {
  UserService.checkCredentials({
    api_key: form.api_key,
    secret_key: form.secret_key
  }).then((response) => {
    if (!response.data.result) {
      return alert('Error: ' + response.data.error_message)
    }
    if (!response.data.can_trade) {
      return alert("You can't trade with this credentials")
    }
    return alert('Done. Your balance BUSD: ' + response.data.balance_busd)
  })
}
</script>

<template>
  <main-section>
    <card-component
      title="New bots"
      :icon="mdiBallot"
      form
      @submit.prevent="submit"
    >
      <field label="Api Key">
        <control
          v-model="form.api_key"
          type="password"
        />
      </field>
      <field label="Secret Key">
        <control
          v-model="form.secret_key"
          type="password"
        />
      </field>

      <divider />

      <jb-buttons>
        <jb-button
          type="submit"
          color="info"
          label="Submit"
        />
        <jb-button
          @click.prevent="check"
          type="button"
          color="info"
          label="Test"
        />
      </jb-buttons>
    </card-component>
  </main-section>
</template>
