<script setup>
import { mdiAccountMultiple } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import ExchangesTable from '@/components/Exchanges/ExchangesTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import HeroBarExchanges from '@/components/Exchanges/HeroBarExchanges'
import UserService from "@/services/user.service";
import {ref} from "vue";

let records = ref([]);
UserService.getExchanges().then((res) => {
  records.value.push(...res.data)
})
</script>

<template>
  <hero-bar-exchanges>New keys</hero-bar-exchanges>
  <main-section>
    <card-component
      class="mb-6"
      title="Exchanges"
      :icon="mdiAccountMultiple"
      has-table
    >
      <exchanges-table checkable :records=records />
    </card-component>
  </main-section>
</template>
