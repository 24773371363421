<script setup>
import { useMainStore } from '@/stores/main'
import ModalBox from "@/components/ModalBox.vue";
import UserService from "@/services/user.service";
import {reactive} from "vue";
import Field from "@/components/Field.vue";
import Control from "@/components/Control.vue";

const props = defineProps({
  user_uuid: String,
  active_modal: Boolean
})

const form = reactive({
  days: '',
  reason: ''
})

const mainStore = useMainStore()
const submit = () => {
  UserService.extendSubscription(
    props.user_uuid,
    form.days,
    form.reason
  ).then(() => {
  }).catch((reason) => {
    alert("error")
  }).finally(() => {
    props.active_modal = false;
    location.reload();
  })
}
const reasons = [
  'paid_subscription',
  'lack_of_activity',
  'promo_conditions'
]
</script>

<template>

  <modal-box
    v-model="active_modal"
    title="Extend user subscription"
    button-label="Confirm"
    @confirm="submit"
  >

    <field label="Days">
      <control type="number" v-model="form.days" />
    </field>

    <field label="Reason">
      <control
        v-model="form.reason"
        :options="reasons"
      />
    </field>
  </modal-box>
</template>
