<script setup>
import { mdiAccountMultiple } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import ExchangesTable from '@/components/Exchanges/ExchangesTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import HeroBarExchanges from '@/components/Exchanges/HeroBarExchanges'
import UserService from "@/services/user.service";
import {ref} from "vue";
import StdJsonTable from "@/components/StdJsonTable.vue";
import TitledSection from "@/components/TitledSection.vue";
import Level from "@/components/Level.vue";
import JbButton from "@/components/JbButton.vue";

let records = ref([]);
let metaInfo = ref({
  binance_spot_count: 0,
  bitget_spot_count: 0,
  binance_futures_count: 0,
  bitget_futures_count: 0,
});

function fetch() {
  UserService.getTickerConnections().then((res) => {
    records.value.push(...res.data.data)
    metaInfo.value = res.data.meta;
  })
}
function refresh() {
  alert('Few moments. Refreshing in 3 seconds')
  setTimeout(() => { location.reload() }, 3000)
}
fetch();
</script>

<template>
  <section
    class="bg-white border-t border-b p-6 dark:bg-gray-900/70 dark:border-gray-900 dark:text-white"
  >
    <level>
      <h1 class="text-3xl font-semibold leading-tight">
        Tickers
      </h1>
      <jb-button
        label="Stop connections"
        color="danger"
        @click="UserService.stopTickerConnections().then(() => { refresh() })"
      />
      <jb-button
        label="Start connections"
        color="success"
        @click="UserService.startTickerConnections().then(() => { refresh() })"
      />
    </level>
  </section>

  <main-section>
    <card-component
      class="mb-6"
      title="Active connections"
      :icon="mdiAccountMultiple"
      has-table
    ><table>
      <tr><td>Binance spot - </td><td>{{metaInfo.binance_spot_count}}</td></tr>
      <tr><td>Bitget spot - </td><td>{{metaInfo.bitget_spot_count}}</td></tr>
      <tr><td>Binance futures - </td><td>{{metaInfo.binance_futures_count}}</td></tr>
      <tr><td>Bitget futures - </td><td>{{metaInfo.bitget_futures_count}}</td></tr>
    </table>
    </card-component>

    <card-component
      class="mb-6"
      title="Tickers"
      :icon="mdiAccountMultiple"
      has-table
    >

      <std-json-table :records=records :per-page="10000" />
    </card-component>
  </main-section>
</template>
